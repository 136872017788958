import React, { FC, Suspense, useState, useEffect } from "react";
import { Layout, Menu, Breadcrumb, Tooltip } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import {
  BrowserRouter as Router,
  Link,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { PrivateRoute } from "../../keycloak/route";
import TopBar from "../head-bar";
import Loading from "../loading";
import { milRoutes, dxpRoutes, RouteItem } from "../../config/routes";
import "./index.less";

const PLATFORM = {
  mil: "mil",
  dxp: "dxp",
};

const iconFontMenuItem: { [key: string]: string } = {
  taskmanagement: "iconwarehouse-storage-",
};

const { SubMenu, Item: MenuItem } = Menu;
const { Content, Sider, Footer } = Layout;

type BasicLayoutProps = {
  changeLang: (value: string) => void;
};

const renderMenu = (routes: RouteItem[], platform: string) =>
  routes.map((route: RouteItem) => {
    const locale = useIntl();
    const Icon = route.icon;
    const name = `menu.${route.name}`;
    const iconType = Object.keys(iconFontMenuItem).includes(route.name)
      ? iconFontMenuItem[route.name]
      : "";
    if (route.children) {
      return (
        <SubMenu
          key={name}
          icon={<Icon type={iconType} />}
          title={
            <Tooltip
              title={<FormattedMessage id={name} />}
              mouseEnterDelay={0.3}
            >
              <FormattedMessage id={name} />
              {/* {locale.messages[name].toString()} */}
            </Tooltip>
          }
        >
          {route.children?.map((item) => {
            const itemName = `${name}.${item.name}`;
            return item.show ? (
              <MenuItem key={itemName}>
                {/* {itemName === "menu.datamanagement.datasets" ? (
                  <a href={`/${platform}${item.path}`}>
                    <FormattedMessage id={itemName} />
                  </a>
                ) : ( */}
                <Link to={`/${platform}${item.path}`}>
                  <FormattedMessage id={itemName} />
                </Link>
                {/* )} */}
              </MenuItem>
            ) : null;
          })}
        </SubMenu>
      );
    } else {
      return route.show ? (
        <MenuItem icon={<Icon type={iconType} />} key={name}>
          <Link to={`/${platform}${route.path}`}>
            <FormattedMessage id={name} />
          </Link>
        </MenuItem>
      ) : null;
    }
  });

type FlattenRouteItem = {
  path: string;
  component: any;
  bread: string[];
};
const flatRoutes = (routes: RouteItem[], platform: string) => {
  const result: FlattenRouteItem[] = [];
  routes.forEach((route) => {
    if (route.children) {
      route.children.forEach((item: RouteItem) => {
        const { path = "", component, breadTail } = item;
        const bread = [`menu.${route.name}`, `menu.${route.name}.${item.name}`];
        if (breadTail) {
          bread.push(`menu.${route.name}.${breadTail}`);
        }
        result.push({ path: `/${platform}${path}`, component, bread });
      });
    } else {
      const { path = "", component, breadTail } = route;
      const bread = [`menu.${route.name}`];
      if (breadTail) {
        bread.push(`menu.${breadTail}`);
      }
      result.push({ path: `/${platform}${path}`, component, bread });
    }
  });
  return result;
};

const flattenMilRoutes = flatRoutes(milRoutes, PLATFORM.mil);
const flattenDxpRoutes = flatRoutes(dxpRoutes, PLATFORM.dxp);

const genDefaultKey = () => {
  const prefix = "menu";
  const { pathname } = window.location;
  const pathList = pathname.split("/").filter((str) => str.length > 0);

  if (pathList.length === 3) {
    return {
      defaultSelectedKeys: [`${prefix}.${pathList[1]}.${pathList[2]}`],
      defaultOpenKeys: [`${prefix}.${pathList[1]}`],
    };
  }
  if (pathList.length === 2) {
    return {
      defaultSelectedKeys: [`${prefix}.${pathList[1]}`],
      defaultOpenKeys: [],
    };
  }
  return {
    defaultSelectedKeys: [],
    defaultOpenKeys: [],
  };
};

const renderContent = (flattenRoutes: FlattenRouteItem[]) => (
  <Switch>
    {flattenRoutes.map((route, i) => {
      const { path } = route;

      // TODO Temporary scheme, to be modified
      const locale = useIntl();
      const firstLevelPath = "/" + window.location.pathname.split("/")[1];
      let headerName = locale.messages["header.dxp"].toString();
      switch (firstLevelPath) {
        case "/dxp":
          headerName = locale.messages["header.dxp"].toString();
          break;
        case "/mil":
          headerName = locale.messages["header.mil"].toString();
          break;
      }
      return (
        <Route path={path} key={`${i}-${path}`}>
          <Layout>
            <Content
              className="site-layout-background"
              style={{
                padding: "8px 20px",
                margin: 0,
                minHeight: 280,
                overflowY: "auto",
              }}
            >
              <Breadcrumb style={{ margin: "12px 0 20px 20px" }}>
                <Breadcrumb.Item>{headerName}</Breadcrumb.Item>
                {route.bread.map((item: string) => {
                  return (
                    <Breadcrumb.Item key={item}>
                      <FormattedMessage id={item} />
                    </Breadcrumb.Item>
                  );
                })}
              </Breadcrumb>
              <Suspense fallback={<Loading />}>
                <route.component />
              </Suspense>
            </Content>
            <Footer>
              <span>
                © {new Date().getFullYear()} Momenta. All rights reserved.
              </span>
              <span>Powered by Closed Loop Automation.</span>
            </Footer>
          </Layout>
        </Route>
      );
    })}

    <Route
      exact
      path="/mil"
      render={() => <Redirect to="/mil/joblist" push />}
    />
    <Route
      exact
      path="/dxp"
      render={() => <Redirect to="/dxp/ingestion_tasks" push />}
    />

    <Route
      path="/dxp/datasets/:dataset_name"
      render={() => <Redirect to="/dxp/datasets" push />}
    />

    <Redirect to="/error" />
  </Switch>
);

const SwitchComponents: FC<any> = (props) => {
  const defaultConf = genDefaultKey();
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const [selectedKeys, setSelectKeys] = useState<Array<any>>([]);

  const reactLocation = useLocation();

  // Can't map to MenuItem key simply through URL information. This is a bad policy
  const nameMap: { [key: string]: string } = {
    datacollection: "menu.taskmanagement.datacollection",
    ingestion_tasks: "menu.taskmanagement.ingestion_tasks",
    process_tasks: "menu.taskmanagement.process_tasks",
    systemtask: "menu.taskmanagement.systemtask",
    processors: "menu.processormanagement.processors",
    views: "menu.datamanagement.views",
    datasets: "menu.datamanagement.datasets",
    data: "menu.datamanagement.data",
    "": "",
  };

  const subNameMap: { [key: string]: { [key: string]: string } } = {
    categories: {
      msd: "menu.datamanagement.msd",
      msd_prediction: "menu.datamanagement.msd_prediction",
      msd_lidar: "menu.datamanagement.msd_lidar",
      msd_od: "menu.datamanagement.msd_od",
      msd_pnc: "menu.datamanagement.msd_pnc",
      parking: "menu.datamanagement.parking",
      mdp: "menu.datamanagement.mdp",
      msp: "menu.datamanagement.msp",
      highway: "menu.datamanagement.highway",
      hdmap: "menu.datamanagement.hdmap",
      pe_system: "menu.datamanagement.pe_system",
      lidar: "menu.datamanagement.lidar",
      mloc: "menu.datamanagement.mloc",
      dxp: "menu.datamanagement.dxp",
      tsr_filter: "menu.datamanagement.tsr_filter",
      Test: "menu.datamanagement.Test",
      "bosch-test": "menu.datamanagement.bosch-test",
      baseai_test: "menu.datamanagement.baseai_test",
      gen3_tsr_test: "menu.datamanagement.gen3_tsr_test",
      "baseai-test-0": "menu.datamanagement.baseai-test-0",
      traffic_sign_ingest_fvg3: "menu.datamanagement.traffic_sign_ingest_fvg3",
      demo: "menu.datamanagement.demo",
      string: "menu.datamanagement.string",
      traffic_Sign: "menu.datamanagement.traffic_Sign",
      updated_type: "menu.datamanagement.updated_type",
      Clip4a: "menu.datamanagement.Clip4a",
      vehicle_filter: "menu.datamanagement.vehicle_filter",
      sfda: "menu.datamanagement.sfda",
    },
  };

  useEffect(() => {
    const menuItem: string = reactLocation.pathname.split("/")[2] ?? "";
    console.log(menuItem);
    if (menuItem in subNameMap) {
      const subItem: string = reactLocation.pathname.split("/")[3] ?? "";
      setSelectKeys([subNameMap[menuItem][subItem]]);
    } else {
      setSelectKeys([nameMap[menuItem]]);
    }
  }, [reactLocation]);

  const onCollapse = (collapsed: boolean) => {
    setCollapsed(collapsed);
  };

  // TODO Currently: when larger than 1100 and stowed, changing the size will expand the sidebar
  const handleResize = () => {
    if (window.innerWidth < 1100) {
      setCollapsed(true);
    } else if (collapsed === true) {
      setCollapsed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize); // destroy
  });

  return (
    <div className="container">
      <TopBar changeLang={props.changeLang} />
      <Layout style={{ height: "calc(100% - 60px)" }}>
        <Switch>
          <Route
            exact
            path="/"
            render={() => <Redirect to="/dxp/ingestion_tasks" push />}
          />
          <Route path={`/${PLATFORM.mil}`}>
            <Sider
              collapsible
              collapsed={collapsed}
              onCollapse={onCollapse}
              width={200}
              theme={"light"}
            >
              <Menu
                mode="inline"
                {...defaultConf}
                style={{ height: "100%", borderRight: 0 }}
              >
                {renderMenu(milRoutes, PLATFORM.mil)}
              </Menu>
            </Sider>
            {renderContent(flattenMilRoutes)}
          </Route>
          <Route path={`/${PLATFORM.dxp}`}>
            <Sider
              collapsible
              collapsed={collapsed}
              onCollapse={onCollapse}
              width={200}
              theme={"light"}
            >
              <Menu
                mode="inline"
                {...defaultConf}
                style={{ height: "100%", borderRight: 0 }}
                defaultOpenKeys={dxpRoutes.map((i) => `menu.${i.name}`)}
                selectedKeys={selectedKeys}
              >
                {renderMenu(dxpRoutes, PLATFORM.dxp)}
              </Menu>
            </Sider>
            {renderContent(flattenDxpRoutes)}
          </Route>
        </Switch>
      </Layout>
    </div>
  );
};

const BasicLayout: FC<BasicLayoutProps> = (
  props: BasicLayoutProps
): JSX.Element => {
  const { initialized } = useKeycloak();

  if (!initialized) {
    return <Loading />;
  }

  const ENABLE_KEYCLOAK_ROUTE_CHECK = !(
    process.env.REACT_APP_DISABLE_KEYCLOAK_ROUTE_CHECK === "true"
  );

  return (
    <Router>
      {ENABLE_KEYCLOAK_ROUTE_CHECK ? (
        <PrivateRoute {...props} path="/" component={SwitchComponents} />
      ) : (
        <SwitchComponents {...props} />
      )}
    </Router>
  );
};

export default BasicLayout;
